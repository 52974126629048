import React, { useEffect, useState } from "react";

export function AppPromoHeader() {
  const [isHidden, setIsHidden] = useState(false);

  useEffect(() => {
    const header = document.querySelector("header.appPromoPage");
    if (header) {
      setIsHidden(true);
    }
  }, []);

  if (isHidden) {
    return null;
  }

  return (
    <div className="appPromo">
      <p className="appText">Tagad arī aplikācijā! Lejupielādē:</p>
      <a href="https://play.google.com/store/apps/details?id=com.flutter.kidson">
        <img
          className="appImage appGoogle"
          src="images/googleplay.svg"
          alt="Google Play"
        />
      </a>
      <a href="https://apps.apple.com/lv/app/kidson-lv/id6740343865">
        <img
          className="appImage appApple"
          src="images/appstore.svg"
          alt="App Store"
        />
      </a>
    </div>
  );
}
